import React, {useEffect} from 'react';
import styled from 'styled-components'
import HeroSection from './components/HeroSection';
import Truested from './components/Truested';
const VeSinhDieuHoa = () => {
  const data={
    name:"Điện Lạnh Quỳnh Thi",
  }
  useEffect(() => {
    document.title = "Vệ sinh đồ điện lạnh";
  }, []);
  return (
    <>
        <HeroSection myData={data}/>
        <Wrapper>
            <div className='container'>
                <div style={{borderRadius: '10px', borderColor: 'black', borderWidth: '2px', borderStyle: 'dotted', padding: '2rem'}}>
                    <h2>VỆ SINH ĐỒ ĐIỆN LẠNH GIÁ RẺ UY TÍN</h2>
                    <p style={{marginTop: '3rem'}}>Trong quá trình sử dụng điều hòa chúng ta thường không chú ý đến việc bảo trì, vệ sinh đồ điện lạnh định kì. Điều này sẽ gây nên những hậu quả khá nghiêm trọng mà chúng ta không lường trước được. Những chiếc điều hòa sẽ cần phải bảo trì định kỳ trong quá trình sử dụng để không gây ảnh hưởng đến sức khỏe con người.</p>
                    <p style={{fontSize: '20px', marginTop: '1rem'}}>DỊCH VỤ VỆ SINH CỦA CHÚNG TÔI:</p>
                    <ol>
                        <li>- Vệ sinh máy lạnh treo tường.</li>
                        <li>- Vệ sinh máy lạnh âm trần.</li>
                        <li>- Vệ sinh máy giặt.</li>
                        <li>- Vệ sinh quạt hơi nước.</li>
                        <li>- Vệ sinh máy lạnh ốp trần.</li>
                    </ol>
                    <p style={{marginTop: '1rem'}}><b style={{fontSize: '18px', color: 'blue'}}>HÃY GỌI NGAY ĐƠN VỊ VỆ SINH GIÁ RẺ TẠI NHÀ CỦA TRUNG TÂM ĐIỆN LẠNH QT.</b></p>
                    
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div className='container2'>
                    <div className='vattu'>
                        <h2 className='h2vattu'>BẢNG GIÁ VỆ SINH MÁY LẠNH</h2>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '1rem'}}>
                            <tbody style={{fontSize: '14px'}}>
                                <tr>
                                    <th>STT</th>
                                    <th>TÊN MÁY</th>
                                    <th>ĐƠN VỊ TÍNH</th>
                                    <th>ĐƠN GIÁ</th>
                                </tr>
                                
                                <tr>
                                    <td>1</td>
                                    <td>Máy lạnh treo tường 1 – 2 HP</td>
                                    <td>Máy</td>
                                    <td>200,000</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Máy lạnh treo tường 2.5 – 3 HP</td>
                                    <td>Máy</td>
                                    <td>200,000</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Máy lạnh âm trần, tủ đứng 3 – 5 HP</td>
                                    <td>Máy</td>
                                    <td>400,000</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Điều hòa hơi nước</td>
                                    <td>Máy</td>
                                    <td>200,000</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    <div className='baoduong'>
                        <h2>BẢNG GIÁ VỆ SINH MÁY GIẶT</h2>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '1rem'}}>
                            <tbody style={{fontSize: '14px'}}>
                                <tr>
                                    <th>STT</th>
                                    <th>TÊN MÁY</th>
                                    <th>ĐƠN VỊ TÍNH</th>
                                    <th>ĐƠN GIÁ</th>
                                </tr>
                                
                                <tr>
                                    <td>1</td>
                                    <td>Máy giặt cửa trước</td>
                                    <td>Máy</td>
                                    <td>500,000</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Máy giặt cửa trên</td>
                                    <td>Máy</td>
                                    <td>300,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/vsmaylanh1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaylanh2.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaylanh3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaylanh4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat2.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Truested/>
    </>
      
    
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
background-color: #f2ffff;
.container1 {
    width: 63%;
    margin: 0 auto;
}
.container2{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.vattu{
    margin-top: 1rem;
    width: 48%;
}
.baoduong{
    margin-top: 1rem;
    width: 48%;
}
.h2vattu{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}

.list-wrapper {
    border-radius: 10px;
    border: 2px dotted black;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 45%;
    height: 50%;
}

.image1 {
    width: 35%;
    margin-right: 100px;
}

@media only screen and (max-width: 600px) {
    .container1 {
        width: 100%;
    }
    .container2 {
        width: 100%;
        flex-direction: column-reverse;
    }

    .content-wrapper {
        flex-direction: column-reverse;
    }
    .vattu{
        width: 95%;
        margin: auto;
    }
    .baoduong{
        width: 95%;
        margin: auto;
    }
    .h2vattu{
        color: #004a82;
        text-align: center;
        padding: 0.2rem;
        margin-top: 2rem;
    }
    .list-wrapper {
        width: 95%;
        margin: auto;
    }

    .image1 {
        width: 95%;
        margin: auto;
    }
}
h2{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}
h3{
    color: #004a82;
    text-align: center;
    font-size: 35px;
}
li{
    font-size: 18px;
}
.logo{
    height: 50%;
}
.table, th, td {
    border:2px solid blue;
    text-align: center;
    padding: 0.25rem;
}

`;

export default VeSinhDieuHoa
