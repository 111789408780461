import React from 'react'
import styled from 'styled-components'
import { SiZalo } from "react-icons/si";
const GoToTop = () => {
    
  return (
    <Wrapper>
        
        <div className="top-btn">
            <a href="https://zalo.me/0326533634" target="_blank">
                <SiZalo className="top-btn--icon" />
            </a>
        </div>
    
    </Wrapper>
  )
}

const Wrapper = styled.section`
display:flex;
justify-content:center;
align-items:center;
.top-btn{
    font-size:4rem;
    width:7rem;
    height:7rem;
    color:#fff;
    background-color:white;
    box-shadow:${({theme})=>theme.colors.shadow};
    border-radius:50%;
    position:fixed;
    bottom:19rem;
    right:2.5rem;
    z-index:999;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;

    &--icon{
        animation: gototop 1.2s linear infinite alternate-reverse;
        color: blue;
    }
    @keyframes gototop {
        0%{
            transform : translateY(-0.5rem);
        }
        100%{
            transform: translateY(1rem);
        }
    }
}
`


export default GoToTop
