import HeroSection from './components/HeroSection'
import styled from "styled-components";
import React, {useEffect} from 'react';

const About = () => {
  const data={
    name: "Điện Lạnh QT"
  }
  useEffect(() => {
    document.title = "Giới thiệu";
  }, []);
  return (
    <>
     {/* {myName} */}
      <HeroSection myData={data} />
      <Wrapper>
        <div className='container'>
          <h2>Lời giới thiệu</h2>
          <h3>Với nhiều năm kinh nghiệm và đội ngũ chuyên viên kỹ thuật được đào tạo chuyên sâu, bài bản, chúng tôi có mặt trên khắp mọi nơi trên địa bàn Bình Dương & Tp. Hồ Chí Minh & Tp. Hồ Chí Minh nhằm phục vụ khách hàng nhanh chóng và hiệu quả nhất có thể.</h3>
          <h3>Dịch vụ sửa chữa điều hòa – máy giặt – tủ lạnh – bếp từ – tivi- bình nóng lạnh tại nhà của chúng tôi hoạt động 24/24 giờ, các ngày trong tuần. Đặc biệt phục vụ cho khách hàng cả vào buổi tối – đêm mà không tính thêm giá dịch vụ.</h3>
          <h3>Sửa chữa có bảo hành dài hạn, có hoá đơn giá trị gia tăng theo yêu cầu của các cơ quan tổ chức  … không mất phí dịch vụ. Nếu như có bất kỳ vấn đề gì trên thiết bị của khách hàng sau khi sửa chữa xong, hãy liên hệ lại với chúng tôi, các chuyên viên kỹ thuật của chúng tôi sẽ quay lại và khắc phục, khách hàng sẽ không phải mất thêm bất kỳ chi phí thêm nữa.</h3>
          <h3>Bên cạnh đó chúng tôi có bộ phận chăm sóc khách hàng sẽ theo dõi quá trình làm việc, tư vấn, sửa chữa của kỹ thuật viên và khách hàng nhằm đảm bảo khách hàng không bị mời thêm chi phí không cần thiết. Mang đến cho khách hàng sự trung thực nhất về giá cả.</h3>
          <h3>Đối với các linh kiện thay thế hay các thiết bị mới, Điện Lạnh QT luôn cam kết 100% với khách hàng về xuất xứ của hàng hóa, 100% là hàng chính hãng, đảm bảo tuyệt đối về chất lượng sản phẩm.</h3>
        </div>
      </Wrapper>
      <Wrapper>
        <div className='container'>
          <h2>Những Cam Kết Của Điện Lạnh QT</h2>
          <ol>
            <li><b>1. </b>Cam kết sử dụng các linh kiện chính hãng để thay thế các bộ phận hỏng đảm bảo tính ổn định và độ bền của máy.</li>
            <li><b>2. </b>Cam kết tư vấn khách hàng về tình trạng và mức độ hư hỏng đồng thời đưa ra giải pháp sửa chữa phù hợp và hiệu quả.</li>
            <li><b>3. </b>Cam kết đưa ra giá cả hợp lý và minh bạch cho các dịch vụ đảm bảo tính minh bạch và trung thực trong giao dịch với khách hàng.</li>
            <li><b>4. </b>Cam kết đáp ứng nhu cầu sửa chữa điều hòa – máy giặt – tủ lạnh – bếp từ – tivi – nóng lạnh của khách hàng trong thời gian ngắn nhất, đồng thời đảm bảo tính chính xác và hiệu quả trong quá trình sửa chữa.</li>
            <li><b>5. </b>Cam kết đảm bảo an toàn cho khách hàng trong quá trình sửa chữa ồng thời đảm bảo sự an toàn và tính bền vững của máy sau khi sửa chữa.</li>
            <li><b>6. </b>Cam kết bảo hành cho các bộ phận đã được thay thế hoặc sửa chữa trong quá trình sửa chữa , đảm bảo tính tin cậy và chất lượng của dịch vụ.</li>
            <li><b>7. </b>Cam kết cung cấp hỗ trợ cho khách hàng sau khi hoàn thành các dịch vụ sửa chữa, đảm bảo tính tiện lợi và sự hài lòng của khách hàng.</li>
          </ol>
        </div>
      </Wrapper>
      <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/maylanh1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/suativi1_1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaylanh3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/binhnonglanh1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/suamaygiat4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/nonglanh3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/maylanh4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/suamaygiat3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                </div>
            </div>
      </Wrapper>
    </>
  
  )
}
const Wrapper = styled.section`
padding: 2rem 0;
background-color: #f2ffff;
.container1 {
  width: 63%;
  margin: 0 auto;
}
h2{
  color: #004a82;
  text-align: center;
  padding: 0.2rem;
}
h3{
  margin-top: 1rem;
  font-size: 23px;
}
li{
  margin-top: 1rem;
  font-size: 23px;
}
b{
  font-size: 25px;
}
`;
export default About;
