import styled from "styled-components";
import { NavLink } from "react-router-dom";
import img1 from './logo1.png'
import vsmaylanh2 from './vsmaylanh2.jpg'
import maylanh1 from './maylanh1.jpg'
import maygiat1 from './maygiat1.jpg'
import binhnonglanh1 from './binhnonglanh1.jpg'
import suativi1 from './suativi1.jpg'
import suatulanh from './suatulanh.webp'
import suadieuhoa from './suadieuhoa.jpg'
import baoduong from './baoduong.jpg'

const FeatureProduct = () => {

  return (
    <Wrapper className="section">
      <div className="container">
        <div className="common-heading">Dịch vụ của chúng tôi</div>
        <div className="grid grid-three-column">
          <NavLink to="/suadieuhoa">
            <div className="container grid grid-filter-column">
              <div style={{borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${suadieuhoa})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', margin: '0 auto'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Sửa điều hòa</h2>
                </div>
              </div>
              
            </div>
          </NavLink>

          <NavLink to="/suatulanh">
            <div className="container grid grid-filter-column">
              <div style={{margin: '0 auto', borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${suatulanh})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Sửa tủ lạnh</h2>
                </div>
              </div>
              
            </div>
          </NavLink>

          <NavLink to="/suativi">
            <div className="container grid grid-filter-column">
              <div style={{margin: '0 auto', borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${suativi1})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Sửa TV</h2>
                </div>
              </div>
              
            </div>
          </NavLink>

          <NavLink to="/suamaygiat">
            <div className="container grid grid-filter-column">
              <div style={{margin: '0 auto', borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${maygiat1})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Sửa máy giặt</h2>
                </div>
              </div>
              
            </div>
          </NavLink>

          <NavLink to="/suabinhnonglanh">
            <div className="container grid grid-filter-column">
              <div style={{margin: '0 auto', borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${binhnonglanh1})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Sửa bình nóng lạnh</h2>
                </div>
              </div>
              
            </div>
          </NavLink>

          <NavLink to="/vesinh">
            <div className="container grid grid-filter-column">
              <div style={{margin: '0 auto', borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${vsmaylanh2})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Vệ sinh đồ điện lạnh</h2>
                </div>
              </div>
              
            </div>
          </NavLink>

          <NavLink to="/baoduong">
            <div className="container grid grid-filter-column">
              <div style={{margin: '0 auto', borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${baoduong})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Bảo dưỡng</h2>
                </div>
              </div>
              
            </div>
          </NavLink>
          
          <NavLink to="/">
            <div className="container grid grid-filter-column">
              <div style={{margin: '0 auto', borderWidth: '1', borderRadius: '10px', borderStyle: 'solid',backgroundImage: `url(${maylanh1})`, backgroundSize: 'cover', width: '300px', height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <div style={{backgroundColor: '#b4b8b8', opacity: '80%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', textAlign: 'center'}}>
                  <h2>Tháo lắp, di dời máy - 550k/bộ</h2>
                </div>
              </div>
              
            </div>
          </NavLink>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 5rem 0;
  background-color: ${({ theme }) => theme.colors.bg};
  h2{
    font-size: 25px;
    font-weight: bold;
  }
  .container {
    max-width: 120rem;
    justify-content: center;
    align-items: center;
  }
  .common-heading{
    text-align: center;
  }
  .grid-filter-column {
    grid-template-columns: 0.2fr;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }
`;

export default FeatureProduct;