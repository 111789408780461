import React, {useEffect} from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import About from "./About";
import Home from "./Home";
import Information from './Information';
import SuaDieuHoa from "./SuaDieuHoa";
import LapDatDieuHoa from './LapDatDieuHoa';
import VeSinhDieuHoa from './VeSinhDieuHoa';
import SuaTuLanh from './SuaTuLanh';
import SuaTivi from './SuaTivi';
import SuaMayGiat from './SuaMayGiat';
import SuaBinhNongLanh from './SuaBinhNongLanh';
import BaoDuong from './BaoDuong';
import Error from "./Error";
import { GlobalStyle } from "./GlobalStyle";
import { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import GoToTop from "./components/GoToTop";
import PhoneCall from "./components/PhoneCall";
import Zalo from "./components/Zalo";
const App = () => {
  useEffect(() => {
    const changeFavicon = (src) => {
        const favicon = document.querySelector('link[rel="shortcut icon"]') ||
                       document.querySelector('link[rel="icon"]');
        favicon.href = src;
      };
    const newFaviconSrc = '/images/logo.ico';
    changeFavicon(newFaviconSrc);
  }, []);
  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",

      bg: "#dee5f3",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      mobile: "768px",
      tab: "998px",
    },
  };
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <GlobalStyle/>
    <Header/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/suadieuhoa" element={<SuaDieuHoa/>}/>
      <Route path="/lapdat" element={<LapDatDieuHoa/>}/>
      <Route path="/vesinh" element={<VeSinhDieuHoa/>}/>
      <Route path="/suatulanh" element={<SuaTuLanh/>}/>
      <Route path="/suativi" element={<SuaTivi/>}/>
      <Route path="/suamaygiat" element={<SuaMayGiat/>}/>
      <Route path="/suabinhnonglanh" element={<SuaBinhNongLanh/>}/>
      <Route path="/baoduong" element={<BaoDuong/>}/>
      <Route path="/information" element={<Information/>}/>
      <Route path="*" element={<Error/>}/>
    </Routes>
    <Zalo />
    <PhoneCall />
    <GoToTop />
    <Footer/>
    </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
