import React, {useEffect} from 'react';
import styled from 'styled-components'
import HeroSection from './components/HeroSection';
import Truested from './components/Truested';
const SuaMayGiat = () => {
  const data={
    name:"Điện Lạnh Quỳnh Thi",
  }
  useEffect(() => {
    document.title = "Sửa máy giặt";
  }, []);
  return (
    <>
        <HeroSection myData={data}/>
        <Wrapper>
            <div className='container'>
                <div style={{padding: '2rem'}}>
                    <h2>TẠI SAO NÊN CHỌN TRUNG TÂM ĐIỆN LẠNH QT?</h2>
                    <p style={{marginTop: '3rem', fontSize: '20px'}}>Trung Tâm Điện tử - Điện lạnh QT chuyên sửa chữa tại nhà tất cả các dòng máy giặt. Bằng tay nghề và sự am hiểu chuyên sâu Kỹ thuật viên có thể bắt bệnh & khắc phục dứt điểm các lỗi. Hạn chế tối đa tình trạng tái phát để không mất thời gian của Quý Khách và uy tín của chúng tôi. Mục tiêu của Trung tâm là hướng đến dịch vụ sửa máy giặt giá rẻ và chất lượng nhất tại Bình Dương & Tp. Hồ Chí Minh.</p>
                    <p style={{fontSize: '25px', fontWeight: 'bold', marginTop: '1rem', color: '#004a82'}}>Ưu điểm khi sửa chữa máy giặt tại Điện lạnh QT:</p>
                        <p style={{fontSize: '20px', marginTop: '1rem'}}><b style={{color: 'red'}}>1. Kinh nghiệm lâu năm trong nghề đã được khẳng định trên thị trường Bình Dương & Tp. Hồ Chí Minh</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>2. Khả năng khắc phục nhanh và triệt để các pan bệnh khó nhất (kể cả các dòng máy hiếm gặp)</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>3. Đội ngũ kỹ thuật viên thường xuyên được đào tạo nâng cao tay nghề hoặc kiểm tra chất lượng để thanh lọc nhân sự yếu kém</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>4. Quy trình dịch vụ rõ ràng, chặt chẽ</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>5. Tách biệt dịch vụ sửa chữa máy giặt và linh kiện thay thế nếu có</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>6. Nhận dịch vụ sửa chữa nếu bạn tự mua các linh kiện nhưng không có khả năng thay thế</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>7. Tư vấn tỉ mỉ, dịch vụ hậu mãi sau bán hàng uy tín, luôn biết lắng nghe ý kiến khách hàng</b></p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div style={{borderRadius: '10px', borderColor: 'black', borderWidth: '2px', borderStyle: 'dotted', padding: '2rem'}}>
                    <h2>SỬA CHỮA MÁY GIẶT GIÁ RẺ UY TÍN</h2>
                    <p style={{marginTop: '3rem'}}>Bạn cần trợ giúp sửa máy giặt và tư vấn cách khắc phục lỗi máy giặt ngay tại nhà và công ty? Gọi ngay <b>Điện lạnh QT</b> nơi hội tụ của những chuyên gia hàng đầu về lĩnh vực sửa máy giặt tại nhà.</p>
                    <p>Chào mừng bạn đến với <b>Điện lạnh QT</b> trung tâm chuyên cung cấp dịch vụ sửa máy giặt tại nhà uy tín, chất lượng bậc nhất tại Bình Dương & Tp. Hồ Chí Minh - địa điểm bảo trì đáng tin tin cậy cho mọi gia đình. <b>Điện lạnh QT</b> chuyên nhận sửa máy giặt tại Bình Dương & Tp. Hồ Chí Minh cùng với đội ngũ kỹ thuật chuyên môn am hiểu tất cả các lỗi trên các hãng máy giặt nhiều công suất. Với nhiều năm kinh nghiệm chúng tôi đảm bảo chuẩn đoán đúng bệnh và đưa ra những giải pháp khắc phục tối ưu nhất nhằm giúp khách hàng tiết kiệm được nhiều chi phí cũng như thời gian.</p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container1'>
                <h2>KHI NÀO CẦN SỬA MÁY GIẶT TẠI ĐIỆN MÁY QT?</h2>
                <div className='content-wrapper'>
                    <div className='list-wrapper'>
                        <ol>
                            <li>- Máy giặt không vào điện hoặc vào điện nhưng không vận hành</li>
                            <li>- Máy giặt phát ra tiếng kêu lạ khi đang chạy</li>
                            <li>- Máy giặt có dấu hiệu giặt không hết chương trình</li>
                            <li>- Máy giặt đứng yên khi chuyển sang chế độ vắt</li>
                            <li>- Máy giặt nháy đèn báo lỗi màn hình liên tục</li>
                            <li>- Máy giặt không cấp nước, cấp nước liên tục</li>
                            <li>- Máy giặt rung mạnh, kêu to</li>
                            <li>- Máy giặt không xả nước</li>
                            <li>- Máy giặt bị kẹt cửa hoặc không mở được cửa</li>
                        </ol>
                    </div>
                    <img src="/images/maygiat1.jpg" className='image1' alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        
        <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/suamaygiat1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suamaygiat2.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suamaygiat3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suamaygiat4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Truested/>
    </>
      
    
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
background-color: #f2ffff;
.container1 {
    width: 63%;
    margin: 0 auto;
}
.container2{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.vattu{
    margin-top: 1rem;
    width: 48%;
}
.baoduong{
    margin-top: 1rem;
    width: 48%;
}
.h2vattu{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}

.list-wrapper {
    border-radius: 10px;
    border: 2px dotted black;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 45%;
    height: 50%;
}

.image1 {
    width: 35%;
    margin-right: 100px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .container1 {
        width: 100%;
    }
    .container2 {
        width: 100%;
        flex-direction: column-reverse;
    }

    .content-wrapper {
        flex-direction: column-reverse;
    }
    .vattu{
        width: 95%;
        margin: auto;
    }
    .baoduong{
        width: 95%;
        margin: auto;
    }
    .h2vattu{
        color: #004a82;
        text-align: center;
        padding: 0.2rem;
        margin-top: 2rem;
    }
    .list-wrapper {
        width: 95%;
        margin: auto;
    }

    .image1 {
        width: 95%;
        margin: auto;
    }
}
h2{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}
h3{
    color: #004a82;
    text-align: center;
    font-size: 35px;
}
li{
    font-size: 18px;
}
.logo{
    height: 50%;
}
.table, th, td {
    border:2px solid blue;
    text-align: center;
    padding: 0.25rem;
}

`;

export default SuaMayGiat
