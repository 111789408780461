import React, {useEffect} from 'react';
import styled from 'styled-components'
import HeroSection from './components/HeroSection';
import Truested from './components/Truested';
const LapDatDieuHoa = () => {
  const data={
    name:"Điện Lạnh Quỳnh Thi",
  }
  useEffect(() => {
    document.title = "Tháo lắp, di dời máy";
  }, []);
  return (
    <>
        <HeroSection myData={data}/>
        <Wrapper>
            <div className='container'>
                <div style={{padding: '2rem'}}>
                    <h2>TẠI SAO NÊN CHỌN TRUNG TÂM ĐIỆN LẠNH QT?</h2>
                    <p style={{marginTop: '3rem', fontSize: '20px'}}>Trung Tâm Điện tử - Điện lạnh QT chuyên sửa chữa tại nhà tất cả các dòng máy giặt. Bằng tay nghề và sự am hiểu chuyên sâu Kỹ thuật viên có thể bắt bệnh & khắc phục dứt điểm các lỗi. Hạn chế tối đa tình trạng tái phát để không mất thời gian của Quý Khách và uy tín của chúng tôi. Mục tiêu của Trung tâm là hướng đến dịch vụ sửa máy giặt giá rẻ và chất lượng nhất tại Bình Dương & Tp. Hồ Chí Minh.</p>
                    <p style={{fontSize: '25px', fontWeight: 'bold', marginTop: '1rem', color: '#004a82'}}>Ưu điểm khi lắp đặt điều hòa tại Điện lạnh QT:</p>
                        <p style={{fontSize: '20px', marginTop: '1rem'}}><b style={{color: 'red'}}>1. Kinh nghiệm lâu năm trong nghề đã được khẳng định trên thị trường Bình Dương & Tp. Hồ Chí Minh</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>2. Khả năng khắc phục nhanh và triệt để các pan bệnh khó nhất (kể cả các dòng máy hiếm gặp)</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>3. Đội ngũ kỹ thuật viên thường xuyên được đào tạo nâng cao tay nghề hoặc kiểm tra chất lượng để thanh lọc nhân sự yếu kém</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>4. Quy trình dịch vụ rõ ràng, chặt chẽ</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>5. Tách biệt dịch vụ sửa chữa máy giặt và linh kiện thay thế nếu có</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>6. Nhận dịch vụ sửa chữa nếu bạn tự mua các linh kiện nhưng không có khả năng thay thế</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>7. Tư vấn tỉ mỉ, dịch vụ hậu mãi sau bán hàng uy tín, luôn biết lắng nghe ý kiến khách hàng</b></p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div style={{borderRadius: '10px', borderColor: 'black', borderWidth: '2px', borderStyle: 'dotted', padding: '2rem'}}>
                    <h2>SỬA CHỮA ĐIỀU HÒA GIÁ RẺ UY TÍN</h2>
                    <p style={{marginTop: '3rem'}}>Là một thành phố lớn có mật động dân cư đông đúc và phát triển về mọi mặt, Bình Dương & Tp. Hồ Chí Minh là hai trong những trung tâm kinh tế lớn nhất của cả nước. Chính vì vậy, nhu cầu sử dụng các thiết bị điện dân dụng cũng như các sản phẩm thiết bị phục vụ cho nhu cầu đời sống ngày càng tăng cao. Sử dụng điều hòa không khí cũng là điều không hề xa lạ, dần trở nên phổ biến và là thiết bị điện thiết yếu không thể thiếu trong gia đình đăc biệt là trong mùa hè khi nhiệt độ có lúc đạt đến hơn 33 độ C.</p>
                    <p style={{fontSize: '20px', marginTop: '1rem'}}>Chúng tôi là trung tâm Điện lạnh QT chuyên sửa chữa điều hòa:</p>
                    <ol>
                        <li>- Điều hòa treo tường</li>
                        <li>- Điều hòa cây</li>
                        <li>- Điều hòa âm trần</li>
                        <li>- Điều hòa ống gió</li>
                        <li>- Điều hòa công nghiệp VRV, VRF,…</li>
                        <li>- Điều hòa muti</li>
                    </ol>
                    <p style={{marginTop: '1rem'}}><b style={{fontSize: '18px', color: 'blue'}}>Trung tâm Điện lạnh QT </b>là địa chỉ uy tín hàng đầu tại Hà Nội về khắc phục hư hỏng của điều hòa giúp không khí trong phòng mát lạnh và tuổi thọ lâu bền hơn. Trung Tâm Điện Lạnh Hà Nội là địa chỉ đầu tiên đưa ra dịch vụ sửa chữa điều hòa có bảo hành tại Hà Nội. Với 20 năm kinh nghiệm, Thợ được đào tạo bài bản từ các trường đại học lớn trong ngành <i style={{fontStyle: 'italic', fontWeight: 'bold'}}>sữa chữa điện lạnh </i>và đã khắc phục cho hàng ngàn khách hàng.</p>
                    <p>Khách hàng chỉ cần nhấc máy lên và gọi yêu cầu <b>sữa chữa </b>khắc phục sự cố điều hòa, nhân viên kĩ thuật sẽ nhanh chóng đến địa chỉ yêu cầu. Mọi hư hỏng đều được sửa chữa dễ dàng dưới sự chuyên nghiệp của thợ.</p>
                    <p>Linh kiện sửa chữa được nhập khẩu từ các cơ sở sản xuất uy tín nên đảm bảo chất lượng và giá thành rẻ hơn so với nhập qua trung gian. Chế độ <b>bảo hành lâu dài </b>cũng hỗ trợ qua điện thoại nhiệt tình là một trong những chế độ chỉ có tại Trung Tâm chúng tôi.</p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container1'>
                <h2>KHI NÀO CẦN SỬA ĐIỀU HÒA TẠI ĐIỆN MÁY QT?</h2>
                <div className='content-wrapper'>
                    <div className='list-wrapper'>
                        <ol>
                            <li>- Điều hòa bị chảy nước</li>
                            <li>- Điều hòa bị đóng tuyết</li>
                            <li>- Điều hòa bị hỏng remote</li>
                            <li>- Máy nén điều hòa không chạy</li>
                            <li>- Điều hòa bị thiếu gas, hết gas</li>
                            <li>- Điều hòa chạy gây ra tiếng ồn lớn</li>
                            <li>- Gió thổi ra khỏi dàn lạnh có mùi hôi</li>
                            <li>- Điều hòa kém lạnh hoặc không lạnh</li>
                            <li>- Block chạy và dừng liên tục do quá tải</li>
                            <li>- Điều hòa bị hỏng mắt đọc và đèn báo lỗi</li>
                        </ol>
                    </div>
                    <img src="/images/logo1.png" className='image1' alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div className='container2'>
                    <div className='vattu'>
                        <h2 className='h2vattu'>BẢNG GIÁ VẬT TƯ</h2>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '1rem'}}>
                            <tbody style={{fontSize: '14px'}}>
                                <tr>
                                    <th>STT</th>
                                    <th>NỘI DUNG CÔNG VIỆC</th>
                                    <th>ĐƠN VỊ TÍNH</th>
                                    <th>ĐƠN GIÁ</th>
                                </tr>
                                
                                <tr>
                                    <td>1</td>
                                    <td>Sửa điều hòa mất nguồn ( chập mạch, hở mạch )</td>
                                    <td>Máy</td>
                                    <td>500,000 - 850,000</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Sửa board mạch điều hòa cơ</td>
                                    <td>Bộ</td>
                                    <td>450,000 - 950,000</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Sửa board mạch điều hòa Inveter</td>
                                    <td>Mạch</td>
                                    <td>850,000 - 1,600,000</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Thay cảm biến dàn lạnh điều hòa</td>
                                    <td>Cái</td>
                                    <td>350,000 - 700,000</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Thay mắt nhận điều hòa	</td>
                                    <td>Cái</td>
                                    <td>400,000 - 650,000</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Thay tụ quạt điều hòa	</td>
                                    <td>Cái</td>
                                    <td>350,000 - 450,000</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Thay quạt dàn lạnh</td>
                                    <td>Cái</td>
                                    <td>650,000 - 1,200,000</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Thay tụ đề lock</td>
                                    <td>Cái</td>
                                    <td>450,000 - 750,000</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Sửa mạch điều hòa tự động Tắt, Bật</td>
                                    <td>Bộ</td>
                                    <td>650,000 - 900,000</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Thay quạt dàn nóng</td>
                                    <td>Bộ</td>
                                    <td>650,000 - 1,200,000</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Sửa điều hòa chảy nước</td>
                                    <td>Máy</td>
                                    <td>250,000 - 350,000</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Sửa điều hòa nháy đèn - báo lỗi</td>
                                    <td>Máy</td>
                                    <td>550,000 - 950,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='baoduong'>
                        <h2>BẢNG GIÁ BẢO DƯỠNG</h2>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '1rem'}}>
                            <tbody style={{fontSize: '14px'}}>
                                <tr>
                                    <th>STT</th>
                                    <th>CÔNG SUẤT</th>
                                    <th>ĐƠN VỊ TÍNH</th>
                                    <th>ĐƠN GIÁ</th>
                                </tr>
                                
                                <tr>
                                    <td>1</td>
                                    <td>9000BTU - 12000BTU</td>
                                    <td>02 - 05</td>
                                    <td>130,000/MÁY</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>18000BTU - 24000BTU</td>
                                    <td>02 - 05</td>
                                    <td>180,000/MÁY</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>9000BTU - 12000BTU</td>
                                    <td>01</td>
                                    <td>200,000/MÁY</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Điều hòa cây 18000BTU - 36000BTU</td>
                                    <td>01 - 05</td>
                                    <td>350,000/MÁY</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Điều hòa âm trần</td>
                                    <td>01 - 05</td>
                                    <td>350,000 - 450,000/MÁY</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Gas R22</td>
                                    <td>PISI</td>
                                    <td>8000/PISI</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Gas R32</td>
                                    <td>PISI</td>
                                    <td>9000/PISI</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Gas R410</td>
                                    <td>PISI</td>
                                    <td>9000/PISI</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                    <img src="/images/logo1.png" style={{width: '25%', flexGrow: '1', flexBasis: '200'}} alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Truested/>
    </>
      
    
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
background-color: #f2ffff;
.container1 {
    width: 63%;
    margin: 0 auto;
}
.container2{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.vattu{
    margin-top: 1rem;
    width: 48%;
}
.baoduong{
    margin-top: 1rem;
    width: 48%;
}
.h2vattu{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}

.list-wrapper {
    border-radius: 10px;
    border: 2px dotted black;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 45%;
    height: 50%;
}

.image1 {
    width: 35%;
    margin-right: 100px;
}

@media only screen and (max-width: 600px) {
    .container1 {
        width: 100%;
    }
    .container2 {
        width: 100%;
        flex-direction: column-reverse;
    }

    .content-wrapper {
        flex-direction: column-reverse;
    }
    .vattu{
        width: 95%;
        margin: auto;
    }
    .baoduong{
        width: 95%;
        margin: auto;
    }
    .h2vattu{
        color: #004a82;
        text-align: center;
        padding: 0.2rem;
        margin-top: 2rem;
    }
    .list-wrapper {
        width: 95%;
        margin: auto;
    }

    .image1 {
        width: 95%;
        margin: auto;
    }
}
h2{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}
h3{
    color: #004a82;
    text-align: center;
    font-size: 35px;
}
li{
    font-size: 18px;
}
.logo{
    height: 50%;
}
.table, th, td {
    border:2px solid blue;
    text-align: center;
    padding: 0.25rem;
}

`;

export default LapDatDieuHoa
