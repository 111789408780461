import React from 'react'
import { NavLink } from 'react-router-dom';
import {Button} from '../styles/Button'
import styled from 'styled-components';
const HeroSection = ({myData}) => {
    // console.log(myData)
    const {name}=myData;
  return (
    <Wrapper>
     <div className='container'>
        <div className="grid grid-two-column">
            <div className="hero-section-data">
              <p className="intro-data"><b>Welcome to</b> </p>
                  <h1>{name}</h1>
                  <ul>
                    <h3>Phục vụ 24/7</h3>
                    <h3>Giá cả phải chăng</h3>
                    <h3>Nhanh chóng, chuyên nghiệp</h3>
                    <h3>Uy tín đặt lên hàng đầu</h3>
                    <h2>Sửa chữa tại nhà</h2>
                  </ul> 
              <NavLink to="/information">
                  <Button>Liên hệ ngay</Button>
              </NavLink>
            </div>
            <div className="hero-section-image">
              <figure>
                  <img src="images/hero.png" alt="hero-section-photo" className="img-style"/>
              </figure>
            </div>
        </div>
     </div>
    </Wrapper>
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
img {
  min-width: 10rem;
  height: 10rem;
}
.hero-section-data {
  p {
    margin: 2rem 0;
    color: #144cba;
  }
  h1 {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 50px;
  }
  .intro-data {
    margin-bottom: 0;
  }
  b {
    font-size: 30px
  }
}
.hero-section-image {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
figure {
  position: relative;
  &::after {
    content: "";
    width: 60%;
    height: 80%;
    background-color: #144cba;
    position: absolute;
    left: 45%;
    top: -2rem;
    z-index: -1;
  }
}
h2{
  color: #144cba;
  margin-top: 10px;
  margin-bottom: 10px;
}
h3{
  margin-top: 10px;
  font-size: 23px;
}
.img-style {
  width: 100%;
  height: auto;
}
@media (max-width: ${({ theme }) => theme.media.mobile}) {
  .grid {
    gap: 1rem;
  }
  figure::after {
    content: "";
    width: 50%;
    height: 93%;
    left: 0;
    top: 10%;
    /* bottom: 10%; */
    background-color: #144cba;
  }
  .img-style {
    width: 100%;
    height: auto;
  }
}
`;
export default HeroSection
