import React, {useEffect} from 'react';
import styled from 'styled-components'
import HeroSection from './components/HeroSection';
import Truested from './components/Truested';
const SuaTivi = () => {
  const data={
    name:"Điện Lạnh Quỳnh Thi",
  }
  useEffect(() => {
    document.title = "Sửa Tivi";
  }, []);
  return (
    <>
        <HeroSection myData={data}/>
        <Wrapper>
            <div className='container'>
                <div style={{padding: '2rem'}}>
                    <h2>TẠI SAO NÊN CHỌN TRUNG TÂM ĐIỆN LẠNH QT?</h2>
                    <p style={{marginTop: '3rem', fontSize: '20px'}}>Trung Tâm Điện tử - Điện lạnh QT chuyên sửa chữa tại nhà tất cả các dòng Tivi. Bằng tay nghề và sự am hiểu chuyên sâu Kỹ thuật viên có thể bắt bệnh & khắc phục dứt điểm các lỗi. Hạn chế tối đa tình trạng tái phát để không mất thời gian của Quý Khách và uy tín của chúng tôi. Mục tiêu của Trung tâm là hướng đến dịch vụ sửa Tivi giá rẻ và chất lượng nhất tại Bình Dương & Tp. Hồ Chí Minh.</p>
                    <p style={{fontSize: '25px', fontWeight: 'bold', marginTop: '1rem', color: '#004a82'}}>Ưu điểm khi sửa chữa tivi tại Điện lạnh QT:</p>
                        <p style={{fontSize: '20px', marginTop: '1rem'}}><b style={{color: 'red'}}>1. Tư vấn mọi thắc mắc:</b> Miễn phí tư vấn giải đáp thắc mắc các vấn đề liên quan đến hỏng hóc hay lỗi tivi.</p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>2. Đội ngũ kỹ thuật viên chuyên nghiệp:</b> Nhân viên đều được đào tạo bài bản từ các môi trường nổi tiếng về Công nghệ.</p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>3. Mức giá phù hợp:</b> Kỹ thuật đến tận nhà kiểm tra vấn đề và tư vấn giải pháp, báo giá sửa chữa tivi tốt nhất. Nếu khách hàng không đồng ý KHÔNG PHẢI trả tiền. Đặc biệt là không phát sinh phụ phí nếu không báo trước.</p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>4. Làm việc 24/7:</b> Trung tâm làm việc tất cả các ngày lễ và T7 và CN, hỗ trợ kỹ thuật & Sửa chữa mọi lúc mọi nơi.</p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>5. Bảo hành dài hạn:</b> Dịch vụ có bảo hành dài hạn, Ưu đãi dài hạn.</p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div style={{borderRadius: '10px', borderColor: 'black', borderWidth: '2px', borderStyle: 'dotted', padding: '2rem'}}>
                    <h2>SỬA CHỮA CÁC LOẠI TIVI UY TÍN</h2>
                    <p style={{fontSize: '23px', color: 'blue', marginTop: '1rem'}}>Tất cả các lỗi hư hỏng, bệnh tivi khó sửa Điện lạnh QT đều giải quyết được. Dịch vụ sửa chữa tivi khắc phục được tất cả các hãng như: Tivi Sony, Samsung, Panasonic, Toshiba, LG, Asanzo,… và nhiều loại tivi khác.</p>
                    <p style={{fontSize: '25px', marginTop: '1rem', fontWeight: 'bold'}}>1. Sửa tivi LG</p>
                        <p>Bạn đang tìm kiếm một đơn vị sửa chữa tivi LG tại nhà nhưng không biết nơi nào uy tín? Bạn thắc mắc về những điều kiện chính sách bảo hành tivi? Bạn đang lo lắng vì chi phí sửa chữa tivi cao? Hay lo sợ việc tốn thời gian và công sức vì phải mang tivi ra tiệm để sửa? Vậy thì hãy đến với <b>Điện lạnh QT</b>, chúng tôi sẽ mang lại trải nghiệm tuyệt vời khi sử dụng dịch vụ sửa tivi LG chuyên nghiệp!</p>
                    
                    <p style={{fontSize: '25px', marginTop: '1rem', fontWeight: 'bold'}}>2. Sửa tivi Sony</p>
                        <p>Tivi Sony khá phổ biến và lâu đời ở Việt Nam. Đây là thương hiệu được định vị đẳng cấp và sang trọng với thiết kế hiện đại có thể phù hợp với nhiều loại nội thất.</p>
                        <p>Hiện nay, nhiều dòng sản phẩm được trang bị công nghệ tiên tiến và những ứng dụng hiện mang tính giải trí cao, phù hợp với nhiều lứa tuổi. Mỗi dòng tivi Sony đều có thế mạnh riêng phù hợp cho từng đối tượng và nhu cầu khách hàng riêng biệt.</p>
                        <p>Tuy nhiên, không có thứ gì là hoàn hảo cả. Với những ưu điểm vượt trội trên thì Sony có giá thành khá đắt, vì thế chi phí sửa cũng cao hơn so với các hãng khác khi thay thế linh kiện.</p>
                        <p>Nếu bạn đang tìm kiếm một đơn vị sửa tivi Sony tại nhà thì không thể bỏ qua <b>Điện lạnh QT</b> nhé!</p>
                    
                    <p style={{fontSize: '25px', marginTop: '1rem', fontWeight: 'bold'}}>3. Sửa tivi Panasonic</p>
                        <p>Panasonic là thương hiệu nổi tiếng tại Việt Nam đã có mặt khá lâu, điển hình là các sản phẩm điện lạnh như tủ lạnh, máy lạnh, máy giặt,…</p>
                        <p>Những sự cố hỏng hóc của tivi đều có nguyên nhân của nó, dưới đây là những thủ phạm chủ yếu gây hư hỏng tivi Panasonic, bạn có thể tham khảo để loại bỏ những nguyên nhân này:</p>
                        <p>– Tivi bị đứt dây cáp, bong mối nối</p>
                        <p>– Chảo bị thay đổi vị trí so với ban đầu</p>
                        <p>– Cáp anten bị hỏng hoặc bị lỏng</p>
                        <p>– Tivi đặt trong môi trường ẩm ướt, không thông thoáng</p>
                        <p>Mọi vấn đề hỏng hóc về tivi, hãy gọi ngay dịch vụ sửa tivi của <b>Điện lạnh QT</b> để được khắc phục nhanh nhất!</p>
                    
                    <p style={{fontSize: '25px', marginTop: '1rem', fontWeight: 'bold'}}>4. Sửa tivi Samsung</p>
                        <p>Với kỹ thuật chuyên môn cao cùng với nhiều năm kinh nghiệm, những vấn đề hư hỏng cần sửa tivi Samsung, <b>Điện lạnh QT</b> đều thực hiện được tại nhà:</p>
                        <p>– Tivi Samsung có hình nhưng không có tiếng</p>
                        <p>– Tivi Samsung có tiếng nhưng không có hình</p>
                        <p>– Tivi Samsung đang chạy thì đột nhiên bị tắt</p>
                        <p>– Tivi Samsung xuất hiện đường kẻ sọc màn hình</p>
                        <p>– Tivi Samsung kêu rè, tiếng nhỏ</p>
                        <p>– Tivi Samsung không kết nối được wifi</p>
                        <p>– Tivi Samsung mất tín hiệu dù đường mạng ổn định</p>
                        <p>– Tivi Samsung không lên nguồn</p>
                        <p>– Tivi Samsung bị cháy nổ cầu chì</p>
                        <p>– Tivi Samsung màn hình không đều, chỗ sáng chỗ tố</p>
                        <p>– Tivi Samsung bị sáng mờ, hình ảnh chớp nháy</p>
                        <p>– Tivi Samsung đang xem thì đột nhiên bị chuyển kênh</p>
                        <p>– Tivi Samsung bị bóng chữ hoặc chồng hai màn hình lên nhau</p>
                        <p>– Tivi Samsung không mở được Youtube hay Facebook, Chorme,…</p>

                    <p style={{fontSize: '25px', marginTop: '1rem', fontWeight: 'bold'}}>5. Sửa tivi Sharp</p>
                        <p>Tivi Sharp là thương hiệu được ưa chuộng nhờ vào chất lượng sử dụng cũng như tuổi thọ của thiết bị. Thiết kế sang trọng của tivi kết hợp cùng những tone màu hiện đại tạo nên điểm nhấn cho không gian căn phòng.</p>
                        <p><b>Ưu điểm của tivi Sharp:</b></p>
                        <p>– Công nghệ điểm ảnh 4 màu độc quyền cho chất lượng hình ảnh sắc nét, sống động và rực rỡ. Trong khi tivi khác chỉ dùng công nghệ 3 màu cơ bản RGB.</p>
                        <p>– Tích hợp âm thanh nổi 3D Yamaha Surround (Công nghệ âm thanh vòm ảo) mang đến cho người dùng trải nghiệm chân thực nhất.</p>
                        <p>– Tiêu thụ điện năng rất ít, nên sẽ tiết kiệm điện năng tiêu thụ cho gia đình bạn.</p>
                        <p>– Khá thân thiện với môi trường khi sử dụng chất liệu dễ tái chế lại hay đèn sử dụng không chứa thủy ngân.</p>
                        <p>Gọi ngay <b>Điện lạnh QT</b> để nhận được những ưu đãi sửa chữa tivi Sharp tại nhà!</p>

                    <p style={{fontSize: '25px', marginTop: '1rem', fontWeight: 'bold'}}>6. Sửa tivi Toshiba</p>
                        <p><b>Ưu điểm của tivi Sharp:</b></p>
                        <p>– Thiết kế đẹp mắt, chắc chắn</p>
                        <p>– Chất lượng màn hình đẹp mắt, chi tiết đầy ấn tượng</p>
                        <p>– Âm thanh vòm đa chiều</p>
                        <p>– Kho ứng dụng phong phú</p>
                        <p>Gọi ngay <b>Điện lạnh QT</b> để nhận được những ưu đãi sửa chữa tivi Sharp tại nhà!</p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container1'>
                <h2>MỘT SỐ LỖI HƯ HỎNG VÀ CÁCH KHẮC PHỤC</h2>
                <div className='content-wrapper'>
                    <div className='list-wrapper'>
                        <p style={{fontSize: '18px', marginTop: '1rem'}}><b>1. Không có hình ảnh</b></p>
                        <p style={{fontSize: '16px'}}><b style={{color: "blue"}}>Cách khắc phục: </b>Kiểm tra các cáp kết nối có đúng hay không hoặc thử thay đổi các tín hiệu như đầu DVD hoặc bộ giải mã.</p>

                        <p style={{fontSize: '18px', marginTop: '1rem'}}><b>2. Không có âm thanh</b></p>
                        <p style={{fontSize: '16px'}}><b style={{color: "blue"}}>Cách khắc phục: </b>Kiểm tra các cài đặt âm thanh của tivi để đảm bảo chúng được cấu hình đúng</p>

                        <p style={{fontSize: '18px', marginTop: '1rem'}}><b>3. Hình ảnh trên tivi bị giật</b></p>
                        <p style={{fontSize: '16px'}}><b style={{color: "blue"}}>Cách khắc phục: </b>Hãy xem tín hiệu đầu vào có đủ băng thông hay không? Hoặc thử điều chỉnh tần số quét của tivi</p>

                        <p style={{fontSize: '18px', marginTop: '1rem'}}><b>4. Tivi không kết nối được Internet</b></p>
                        <p style={{fontSize: '16px'}}><b style={{color: "blue"}}>Cách khắc phục: </b>Hãy kiểm tra xem mật khẩu wifi có đúng không hoặc kết nối tivi với một bộ khuếch đại sóng wifi khác</p>

                        <p style={{fontSize: '18px', marginTop: '1rem'}}><b>5. Tivi không nhận được tín hiệu</b></p>
                        <p style={{fontSize: '16px'}}><b style={{color: "blue"}}>Cách khắc phục: </b>Kiểm tra các bộ thiết bị ngoại vi có còn hoạt động hoặc thử thay đổi các cổng kết nối</p>
                        
                        <p style={{fontSize: '18px', marginTop: '1rem'}}><b>6. Màn hình bị đốm</b></p>
                        <p style={{fontSize: '16px'}}><b style={{color: "blue"}}>Cách khắc phục: </b>Dùng khăn ẩm làm sạch màn hình hoặc gọi dịch vụ vệ sinh tivi tại nhà bạn</p>

                        <p style={{fontSize: '18px', marginTop: '1rem'}}><b>7. Tivi chập điện</b></p>
                        <p style={{fontSize: '16px'}}><b style={{color: "blue"}}>Cách khắc phục: </b>Hãy gọi ngay kĩ thuật bên trung tâm chúng tối để được qua tận nơi xử lí nhanh nhất tránh hư hỏng nặng</p>
                    
                        <p style={{fontSize: '18px', marginTop: '1.5rem'}}><b style={{color: "blue"}}>Tất cả các lỗi hư hỏng, bệnh tivi khó sửa Điện lạnh QT đều giải quyết được. Dịch vụ sửa chữa tivi khắc phục được tất cả các hãng như: Tivi Sony, Samsung, Panasonic, Toshiba, LG, Asanzo,… và nhiều loại tivi khác.</b></p>
                    </div>
                </div>
            </div>
        </Wrapper>
        
        <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/suativi1_1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suativi2.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suativi3.jpeg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suativi4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Truested/>
    </>
      
    
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
background-color: #f2ffff;
.container1 {
    width: 63%;
    margin: 0 auto;
}
.container2{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-wrapper {
    margin-top: 2rem;
}
.vattu{
    margin-top: 1rem;
    width: 48%;
}
.baoduong{
    margin-top: 1rem;
    width: 48%;
}
.h2vattu{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}

.list-wrapper {
    border-radius: 10px;
    border: 2px dotted black;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 100%;
}

.image1 {
    width: 35%;
    margin-right: 100px;
}

@media only screen and (max-width: 600px) {
    .container1 {
        width: 100%;
    }
    .container2 {
        width: 100%;
        flex-direction: column-reverse;
    }

    .content-wrapper {
        flex-direction: column-reverse;
    }
    .vattu{
        width: 95%;
        margin: auto;
    }
    .baoduong{
        width: 95%;
        margin: auto;
    }
    .h2vattu{
        color: #004a82;
        text-align: center;
        padding: 0.2rem;
        margin-top: 2rem;
    }
    .list-wrapper {
        width: 95%;
        margin: auto;
    }

    .image1 {
        width: 95%;
        margin: auto;
    }
}
h2{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}
h3{
    color: #004a82;
    text-align: center;
    font-size: 35px;
}
li{
    font-size: 18px;
}
.logo{
    height: 50%;
}
.table, th, td {
    border:2px solid blue;
    text-align: center;
    padding: 0.25rem;
}

`;

export default SuaTivi