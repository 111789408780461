import React from "react";
import styled from "styled-components";
import {FaFacebook} from "react-icons/fa";
import { SiZalo } from "react-icons/si";

const Footer = () => {
  return (
    <>
      <Wrapper>
        {/* footer section */}

        <footer>
          <div className="container grid grid-four-column">
            <div className="footer-about">
              <h2>Điện Lạnh QT</h2>
              <p>Dịch vụ sửa chữa điều hòa – máy giặt – tủ lạnh – bếp từ – tivi - bình nóng lạnh tại nhà của chúng tôi hoạt động 24/24 giờ, các ngày trong tuần. </p>
            </div>
            <div className="footer-subscribe">
              <h2>Địa chỉ</h2>
              <a target="_blank"><h3>Dĩ An, Bình Dương</h3></a>
            </div>
            <div className="footer-social">
              <h2>Social</h2>
              <div className="footer-social--icons">
                
                <div>
                <a
                    href="https://www.facebook.com/profile.php?id=61553305449363"
                    target="_blank">
                  <FaFacebook className="icons" />
                  </a>
                </div>
                <div>
                  <a
                    href="https://zalo.me/0326533634"
                    target="_blank">
                    <SiZalo className="icons" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-contact">
              <h2>Liên hệ</h2>
              <a href="tel:0326533634"target="_blank"><h3>0326533634</h3></a>
              <h2>Email</h2>
              <h3>dienlanhqt24h@gmail.com</h3>
            </div>
          </div>

          
        </footer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  .iSIFGq {
    margin: 0;
  }

  .contact-short {
    max-width: 60vw;
    margin: auto;
    padding: 5rem 10rem;
    background-color: ${({ theme }) => theme.colors.bg};
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: translateY(50%);

    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }
  }

  footer {
    padding: 2rem 0 2rem 0;
    background-color: #001466;
    h2 {
      color: ${({ theme }) => theme.colors.hr};
      margin-bottom: 2.4rem;
    }
    h3 {
      color: ${({ theme }) => theme.colors.hr};
      margin-bottom: 2.4rem;
    }
    p {
      color: ${({ theme }) => theme.colors.white};
    }
    .footer-social--icons {
      display: flex;
      gap: 2rem;

      div {
        padding: 1rem;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.white};

        .icons {
          color: ${({ theme }) => theme.colors.white};
          font-size: 2.4rem;
          position: relative;
          cursor: pointer;
        }
      }
    }
  }

  .footer-bottom--section {
    padding-top: 5rem;

    hr {
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.colors.hr};
      height: 0.1px;
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .contact-short {
      max-width: 80vw;
      margin: 4.8rem auto;
      transform: translateY(0%);
      text-align: center;

      .grid div:last-child {
        justify-self: center;
      }
    }

    footer {
      padding: 10rem 0 10rem 0;
    }

    .footer-bottom--section {
      padding-top: 4.8rem;
    }
  }
`;

export default Footer;