import React from 'react'
import styled from "styled-components";
const Truested = () => {
  return (
    <Wrapper className="brand-section">
      <div className="container">
        <h3>Đối tác của chúng tôi</h3>
        <div className="brand-section-slider">
          
            <img
              src="https://logos-world.net/wp-content/uploads/2020/12/Electrolux-Logo-700x394.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />
          
          
            <img
              src="https://logos-world.net/wp-content/uploads/2020/05/LG-Logo-700x394.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />
          
          
            <img
              src="https://logos-world.net/wp-content/uploads/2023/03/Panasonic-Logo-500x281.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />

            <img
              src="https://logos-world.net/wp-content/uploads/2023/03/Sanyo-Logo-500x281.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />
            <img
              src="https://logos-world.net/wp-content/uploads/2023/03/Sharp-Logo-500x281.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />
            <img
              src="https://logos-world.net/wp-content/uploads/2020/11/Hitachi-Logo-700x394.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />
            <img
              src="https://logos-world.net/wp-content/uploads/2023/03/Toshiba-Logo-500x281.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />
            <img
              src="https://logos-world.net/wp-content/uploads/2023/01/Mitsubishi-Electric-Logo-500x281.png"
              alt="trusted-brands"
              style={{width: '10%', height: 'auto', margin: '0 auto'}}
            />
          
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding: 2rem 0;
  background-color: white;
  .brand-section {
    padding: 10rem 0 0 0;
  }
  h3 {
    text-align: center;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.text};
    font-size: 3rem;
    font-weight: bold;
  }
  img {
    min-width: 10rem;
    height: 10rem;
  }
  
  .brand-section-slider {
    
    margin-top: 3.2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .brand-section-slider {
      margin-top: 3.2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      /* background-color: red; */
      text-align: center;
      align-items: center;
    }
  }
`;
export default Truested
