import React from 'react'
import styled from 'styled-components'
import { MdCall } from "react-icons/md";
const GoToTop = () => {
    
  return (
    <Wrapper>
        
    <div className="top-btn">
        <a href="tel:0326533634" target="_blank">
            <MdCall className='top-btn--icon'/>
        </a>
    </div>
    
    </Wrapper>
  )
}

const Wrapper = styled.section`
display:flex;
justify-content:center;
align-items:center;
.top-btn{
    font-size:4rem;
    width:7rem;
    height:7rem;
    color:#fff;
    background-color:#2A00C2;
    box-shadow:${({theme})=>theme.colors.shadow};
    border-radius:50%;
    position:fixed;
    bottom:11rem;
    right:2.5rem;
    z-index:999;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;

    &--icon{
        animation: gototop 1.2s linear infinite alternate-reverse;
        color: white;
    }
    @keyframes gototop {
        0%{
            transform : translateY(-0.5rem);
        }
        100%{
            transform: translateY(1rem);
        }
    }
}
`


export default GoToTop
