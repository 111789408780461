import React, {useEffect} from 'react';
import styled from 'styled-components'
import HeroSection from './components/HeroSection';
import Truested from './components/Truested';
const SuaTuLanh = () => {
  const data={
    name:"Điện Lạnh Quỳnh Thi",
  }
  useEffect(() => {
    document.title = "Sửa tủ lạnh";
  }, []);
  return (
    <>
        <HeroSection myData={data}/>
        <Wrapper>
            <div className='container'>
                <div style={{padding: '2rem'}}>
                    <h2>TẠI SAO NÊN CHỌN TRUNG TÂM ĐIỆN LẠNH QT?</h2>
                    <p style={{marginTop: '3rem', fontSize: '20px'}}>Trung Tâm Điện tử - Điện lạnh QT chuyên sửa chữa tại nhà tất cả các dòng máy giặt. Bằng tay nghề và sự am hiểu chuyên sâu Kỹ thuật viên có thể bắt bệnh & khắc phục dứt điểm các lỗi. Hạn chế tối đa tình trạng tái phát để không mất thời gian của Quý Khách và uy tín của chúng tôi. Mục tiêu của Trung tâm là hướng đến dịch vụ sửa máy giặt giá rẻ và chất lượng nhất tại Bình Dương & Tp. Hồ Chí Minh.</p>
                    <p style={{fontSize: '25px', fontWeight: 'bold', marginTop: '1rem', color: '#004a82'}}>Ưu điểm khi sửa chữa tủ lạnh tại Điện lạnh QT:</p>
                        <p style={{fontSize: '20px', marginTop: '1rem'}}><b style={{color: 'red'}}>1. Kinh nghiệm lâu năm trong nghề đã được khẳng định trên thị trường Bình Dương & Tp. Hồ Chí Minh</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>2. Khả năng khắc phục nhanh và triệt để các pan bệnh khó nhất (kể cả các dòng máy hiếm gặp)</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>3. Đội ngũ kỹ thuật viên thường xuyên được đào tạo nâng cao tay nghề hoặc kiểm tra chất lượng để thanh lọc nhân sự yếu kém</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>4. Quy trình dịch vụ rõ ràng, chặt chẽ</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>5. Tách biệt dịch vụ sửa chữa máy giặt và linh kiện thay thế nếu có</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>6. Nhận dịch vụ sửa chữa nếu bạn tự mua các linh kiện nhưng không có khả năng thay thế</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>7. Tư vấn tỉ mỉ, dịch vụ hậu mãi sau bán hàng uy tín, luôn biết lắng nghe ý kiến khách hàng</b></p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div style={{borderRadius: '10px', borderColor: 'black', borderWidth: '2px', borderStyle: 'dotted', padding: '2rem'}}>
                    <h2>DỊCH VỤ SỬA TỦ LẠNH GIÁ RẺ UY TÍN</h2>
                    <p style={{marginTop: '3rem'}}>Một địa chỉ cung cấp quy trình dịch vụ tốt họ sẽ cố gắng tiếp thu tiếp nhận mang những thông tin tin cậy đến khách hàng nhằm mục đích muốn khách hàng hiểu rõ hơn về trung tâm của mình.</p>
                    <p style={{marginTop: '1rem'}}><b style={{fontSize: '18px', color: 'blue'}}>Trung tâm Điện lạnh QT </b>là địa chỉ uy tín hàng đầu tại Bình Dương & Tp. Hồ Chí Minh. Trung Tâm Điện Lạnh QT là địa chỉ đầu tiên đưa ra dịch vụ sửa chữa điều hòa có bảo hành tại Hà Nội. Với 20 năm kinh nghiệm, Thợ được đào tạo bài bản từ các trường đại học lớn trong ngành <i style={{fontStyle: 'italic', fontWeight: 'bold'}}>sữa chữa điện lạnh </i>và đã khắc phục cho hàng ngàn khách hàng.</p>
                    <p>Khách hàng chỉ cần nhấc máy lên và gọi yêu cầu <b>sửa chữa </b>khắc phục sự cố tủ lạnh, nhân viên kĩ thuật sẽ nhanh chóng đến địa chỉ yêu cầu. Mọi hư hỏng đều được sửa chữa dễ dàng dưới sự chuyên nghiệp của thợ.</p>
                    <p>Linh kiện sửa chữa được nhập khẩu từ các cơ sở sản xuất uy tín nên đảm bảo chất lượng và giá thành rẻ hơn so với nhập qua trung gian. Chế độ <b>bảo hành lâu dài </b>cũng hỗ trợ qua điện thoại nhiệt tình là một trong những chế độ chỉ có tại Trung Tâm chúng tôi.</p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container1'>
                <h2>DỊCH VỤ SỬA TỦ LẠNH TẠI ĐIỆN MÁY QT:</h2>
                <div className='content-wrapper'>
                    <div className='list-wrapper'>
                        <ol>
                            <li>- Sửa tủ lạnh Hitachi </li>
                            <li>- Sửa tủ lạnh Sharp</li>
                            <li>- Sửa tủ lạnh Samsung</li>
                            <li>- Sửa tủ lạnh LG</li>
                            <li>- Sửa tủ lạnh Sanyo</li>
                            <li>- Sửa tủ lạnh Panasonic</li>
                            <li>- Sửa tủ lạnh Toshiba</li>
                            <li>- Sửa tủ lạnh Electrolux</li>
                            <li>- Sửa tủ lạnh Mitsubishi</li>
                            <li>Và một số hãng tủ lạnh khác...</li>
                        </ol>
                    </div>
                    <img src="/images/suatulanh.webp" className='image1' alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        
        <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/suatulanh1.png" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suatulanh2.png" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suatulanh3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/suatulanh4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Truested/>
    </>
      
    
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
background-color: #f2ffff;
.container1 {
    width: 63%;
    margin: 0 auto;
}
.container2{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.vattu{
    margin-top: 1rem;
    width: 48%;
}
.baoduong{
    margin-top: 1rem;
    width: 48%;
}
.h2vattu{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}

.list-wrapper {
    border-radius: 10px;
    border: 2px dotted black;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 45%;
    height: 50%;
}

.image1 {
    width: 35%;
    margin-right: 100px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .container1 {
        width: 100%;
    }
    .container2 {
        width: 100%;
        flex-direction: column-reverse;
    }

    .content-wrapper {
        flex-direction: column-reverse;
    }
    .vattu{
        width: 95%;
        margin: auto;
    }
    .baoduong{
        width: 95%;
        margin: auto;
    }
    .h2vattu{
        color: #004a82;
        text-align: center;
        padding: 0.2rem;
        margin-top: 2rem;
    }
    .list-wrapper {
        width: 95%;
        margin: auto;
    }

    .image1 {
        width: 95%;
        margin: auto;
    }
}
h2{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}
h3{
    color: #004a82;
    text-align: center;
    font-size: 35px;
}
li{
    font-size: 25px;
}
.logo{
    height: 50%;
}
.table, th, td {
    border:2px solid blue;
    text-align: center;
    padding: 0.25rem;
}

`;

export default SuaTuLanh
