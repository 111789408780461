import React, {useEffect} from 'react';
import styled from 'styled-components'
import HeroSection from './components/HeroSection';
import Truested from './components/Truested';
const BaoDuong = () => {
  const data={
    name:"Điện Lạnh Quỳnh Thi",
  }
  useEffect(() => {
    document.title = "Bảo dưỡng";
  }, []);
  return (
    <>
        <HeroSection myData={data}/>
        <Wrapper>
            <div className='container'>
                <div style={{padding: '2rem'}}>
                    <h2>TẠI SAO NÊN CHỌN TRUNG TÂM ĐIỆN LẠNH QT?</h2>
                    <p style={{marginTop: '3rem', fontSize: '20px'}}>Trung Tâm Điện tử - Điện lạnh QT chuyên sửa chữa tại nhà tất cả các dòng máy giặt. Bằng tay nghề và sự am hiểu chuyên sâu Kỹ thuật viên có thể bắt bệnh & khắc phục dứt điểm các lỗi. Hạn chế tối đa tình trạng tái phát để không mất thời gian của Quý Khách và uy tín của chúng tôi. Mục tiêu của Trung tâm là hướng đến dịch vụ sửa máy giặt giá rẻ và chất lượng nhất tại Bình Dương & Tp. Hồ Chí Minh.</p>
                    <p style={{fontSize: '25px', fontWeight: 'bold', marginTop: '1rem', color: '#004a82'}}>Ưu điểm khi bảo dưỡng tại Điện lạnh QT:</p>
                        <p style={{fontSize: '20px', marginTop: '1rem'}}><b style={{color: 'red'}}>1. Kinh nghiệm lâu năm trong nghề đã được khẳng định trên thị trường Bình Dương & Tp. Hồ Chí Minh</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>2. Khả năng khắc phục nhanh và triệt để các pan bệnh khó nhất (kể cả các dòng máy hiếm gặp)</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>3. Đội ngũ kỹ thuật viên thường xuyên được đào tạo nâng cao tay nghề hoặc kiểm tra chất lượng để thanh lọc nhân sự yếu kém</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>4. Quy trình dịch vụ rõ ràng, chặt chẽ</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>5. Tách biệt dịch vụ sửa chữa máy giặt và linh kiện thay thế nếu có</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>6. Nhận dịch vụ sửa chữa nếu bạn tự mua các linh kiện nhưng không có khả năng thay thế</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>7. Tư vấn tỉ mỉ, dịch vụ hậu mãi sau bán hàng uy tín, luôn biết lắng nghe ý kiến khách hàng</b></p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div style={{borderRadius: '10px', borderColor: 'black', borderWidth: '2px', borderStyle: 'dotted', padding: '2rem'}}>
                    <h2>SỬA CHỮA ĐIỀU HÒA GIÁ RẺ UY TÍN</h2>
                    <p style={{marginTop: '3rem'}}>Là một thành phố lớn có mật động dân cư đông đúc và phát triển về mọi mặt, Bình Dương & Tp. Hồ Chí Minh là hai trong những trung tâm kinh tế lớn nhất của cả nước. Chính vì vậy, nhu cầu sử dụng các thiết bị điện dân dụng cũng như các sản phẩm thiết bị phục vụ cho nhu cầu đời sống ngày càng tăng cao. Sử dụng điều hòa không khí cũng là điều không hề xa lạ, dần trở nên phổ biến và là thiết bị điện thiết yếu không thể thiếu trong gia đình đăc biệt là trong mùa hè khi nhiệt độ có lúc đạt đến hơn 33 độ C.</p>
                    <p style={{fontSize: '20px', marginTop: '1rem'}}>Chúng tôi là trung tâm Điện lạnh QT chuyên sửa chữa điều hòa:</p>
                    <ol>
                        <li>- Điều hòa treo tường</li>
                        <li>- Điều hòa cây</li>
                        <li>- Điều hòa âm trần</li>
                        <li>- Điều hòa ống gió</li>
                        <li>- Điều hòa công nghiệp VRV, VRF,…</li>
                        <li>- Điều hòa muti</li>
                    </ol>
                    <p style={{marginTop: '1rem'}}><b style={{fontSize: '18px', color: 'blue'}}>Trung tâm Điện lạnh QT </b>là địa chỉ uy tín hàng đầu tại Hà Nội về khắc phục hư hỏng của điều hòa giúp không khí trong phòng mát lạnh và tuổi thọ lâu bền hơn. Trung Tâm Điện Lạnh Hà Nội là địa chỉ đầu tiên đưa ra dịch vụ sửa chữa điều hòa có bảo hành tại Hà Nội. Với 20 năm kinh nghiệm, Thợ được đào tạo bài bản từ các trường đại học lớn trong ngành <i style={{fontStyle: 'italic', fontWeight: 'bold'}}>sữa chữa điện lạnh </i>và đã khắc phục cho hàng ngàn khách hàng.</p>
                    <p>Khách hàng chỉ cần nhấc máy lên và gọi yêu cầu <b>sữa chữa </b>khắc phục sự cố điều hòa, nhân viên kĩ thuật sẽ nhanh chóng đến địa chỉ yêu cầu. Mọi hư hỏng đều được sửa chữa dễ dàng dưới sự chuyên nghiệp của thợ.</p>
                    <p>Linh kiện sửa chữa được nhập khẩu từ các cơ sở sản xuất uy tín nên đảm bảo chất lượng và giá thành rẻ hơn so với nhập qua trung gian. Chế độ <b>bảo hành lâu dài </b>cũng hỗ trợ qua điện thoại nhiệt tình là một trong những chế độ chỉ có tại Trung Tâm chúng tôi.</p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container1'>
                <h2>KHI NÀO CẦN GỌI BẢO DƯỠNG TẠI ĐIỆN MÁY QT?</h2>
                <div className='content-wrapper'>
                    <div className='list-wrapper'>
                        <p style={{fontSize: '20px'}}>Điều hoà sau một thời gian sử dụng sẽ bị bám bẩn do đó bạn cần phải bảo dưỡng định kỳ để máy hoạt động ổn định, sạch sẽ giúp bảo vệ sức khỏe cho cả gia đình. Thông thường, thời gian phù hợp để vệ sinh máy lạnh, bảo dưỡng máy lạnh đó là bạn cần thực hiện khoảng 3 – 4 tháng một lần. Tuy nhiên, đối với những chiếc điều hoà được lắp đặt ở những khu vực hay môi trường sản xuất kinh doanh, hoạt động nhiều và dễ bám bụi thì bạn cần phải thực hiện bảo dưỡng từ 2 – 3 tháng một lần.Khi nhận thấy máy lạnh có những tình trạng sau thì bạn cần bảo dưỡng ngay không cần đợi đến đúng thời hạn.</p>
                        <p style={{fontSize: '20px'}}>1. Máy lạnh hoạt động yếu, không đúng công suất mặc hoặc máy lạnh không lạnh.</p>
                        <p style={{fontSize: '20px'}}>2. Khi nhận thấy máy lạnh hoạt động không còn êm ái chạy kêu to ở 2 dàn nóng và dàn lạnh. Nguyên nhân chính là do máy bị bám bụi ở moter và cánh quạt. Bạn chỉ cần vệ sinh sạch là máy có thể chạy êm ái như ban đầu</p>
                        <p style={{fontSize: '20px'}}>3. Máy lạnh bị thiếu gas</p>
                        <p style={{fontSize: '20px'}}>4. Khi máy lạnh có tình trạng chảy nước ở dàn lạnh. Nguyên nhân là do máy thiếu gas, tuyết bám trên dàn lạnh hoặc có thể do bị nghẹt ở đường ống thoát nước do bị bụi.</p>
                        <p style={{fontSize: '20px'}}>5. Máy lạnh tiêu thụ điện năng so với mức bình thường.</p>
                    </div>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <div className='container2'>
                    <div className='vattu'>
                        <h2 className='h2vattu'>BẢNG GIÁ VẬT TƯ</h2>
                        <table style={{width: '100%', borderCollapse: 'collapse', marginTop: '1rem'}}>
                            <tbody style={{fontSize: '18px'}}>
                                <tr>
                                    <th>STT</th>
                                    <th>CÔNG SUẤT</th>
                                    <th>ĐƠN VỊ TÍNH</th>
                                    <th>ĐƠN GIÁ</th>
                                </tr>
                                
                                <tr>
                                    <td>1</td>
                                    <td>Điều hòa cây 9000BTU - 12000BTU</td>
                                    <td>01</td>
                                    <td>200,000/MÁY</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Điều hòa cây 18000BTU - 36000BTU</td>
                                    <td>01</td>
                                    <td>350,000/MÁY</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Điều hòa âm trần</td>
                                    <td>01</td>
                                    <td>350,000 - 450,000/máy</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Gas R22</td>
                                    <td>PISI</td>
                                    <td>8000/PISI</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Gas R32 + R410</td>
                                    <td>PISI</td>
                                    <td>9000/PISI</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/vsmaylanh1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaylanh2.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaylanh3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaylanh4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat2.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                    <img src="/images/vsmaygiat4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto', marginTop: '5px'}} alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Truested/>
    </>
      
    
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
background-color: #f2ffff;
.container1 {
    width: 63%;
    margin: 0 auto;
}
.container2{
    width: 100%;
    margin: 0 auto;
}
.content-wrapper {
    margin-top: 2rem;
}
.vattu{
    margin: 0 auto;
    width: 60%;
}
.baoduong{
    margin-top: 1rem;
    width: 40%;
}
.h2vattu{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}

.list-wrapper {
    border-radius: 10px;
    border: 2px dotted black;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    margin: 0 auto;
}

.image1 {
    width: 35%;
    margin-right: 100px;
}

@media only screen and (max-width: 600px) {
    .container1 {
        width: 100%;
    }
    .container2 {
        width: 100%;
        flex-direction: column-reverse;
    }

    .content-wrapper {
        flex-direction: column-reverse;
    }
    .vattu{
        width: 95%;
        margin: auto;
    }
    .baoduong{
        width: 95%;
        margin: auto;
    }
    .h2vattu{
        color: #004a82;
        text-align: center;
        padding: 0.2rem;
        margin-top: 2rem;
    }
    .list-wrapper {
        width: 95%;
        margin: auto;
    }

    .image1 {
        width: 95%;
        margin: auto;
    }
}
h2{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}
h3{
    color: #004a82;
    text-align: center;
    font-size: 35px;
}
li{
    font-size: 18px;
}
.logo{
    height: 50%;
}
.table, th, td {
    border:2px solid blue;
    text-align: center;
    padding: 0.25rem;
}

`;

export default BaoDuong
