import React, {useEffect} from 'react';
import styled from 'styled-components'
import HeroSection from './components/HeroSection';
import Truested from './components/Truested';
const SuaBinhNongLanh = () => {
  const data={
    name:"Điện Lạnh Quỳnh Thi",
  }
  useEffect(() => {
    document.title = "Sửa bình nóng lạnh";
  }, []);
  return (
    <>
        <HeroSection myData={data}/>
        <Wrapper>
            <div className='container'>
                <div style={{padding: '2rem'}}>
                    <h2>TẠI SAO NÊN CHỌN TRUNG TÂM ĐIỆN LẠNH QT?</h2>
                    <p style={{marginTop: '3rem', fontSize: '20px'}}>Trung Tâm Điện tử - Điện lạnh QT chuyên sửa chữa tại nhà tất cả các dòng máy giặt. Bằng tay nghề và sự am hiểu chuyên sâu Kỹ thuật viên có thể bắt bệnh & khắc phục dứt điểm các lỗi. Hạn chế tối đa tình trạng tái phát để không mất thời gian của Quý Khách và uy tín của chúng tôi. Mục tiêu của Trung tâm là hướng đến dịch vụ sửa máy giặt giá rẻ và chất lượng nhất tại Bình Dương & Tp. Hồ Chí Minh.</p>
                    <p style={{fontSize: '25px', fontWeight: 'bold', marginTop: '1rem', color: '#004a82'}}>Ưu điểm khi sửa chữa bình nóng lạnh tại Điện lạnh QT:</p>
                        <p style={{fontSize: '20px', marginTop: '1rem'}}><b style={{color: 'red'}}>1. Kinh nghiệm lâu năm trong nghề đã được khẳng định trên thị trường Bình Dương & Tp. Hồ Chí Minh</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>2. Khả năng khắc phục nhanh và triệt để các pan bệnh khó nhất (kể cả các dòng máy hiếm gặp)</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>3. Đội ngũ kỹ thuật viên thường xuyên được đào tạo nâng cao tay nghề hoặc kiểm tra chất lượng để thanh lọc nhân sự yếu kém</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>4. Quy trình dịch vụ rõ ràng, chặt chẽ</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>5. Tách biệt dịch vụ sửa chữa máy giặt và linh kiện thay thế nếu có</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>6. Nhận dịch vụ sửa chữa nếu bạn tự mua các linh kiện nhưng không có khả năng thay thế</b></p>
                        <p style={{fontSize: '20px', marginTop: '0.5rem'}}><b style={{color: 'red'}}>7. Tư vấn tỉ mỉ, dịch vụ hậu mãi sau bán hàng uy tín, luôn biết lắng nghe ý kiến khách hàng</b></p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container'>
                <h2>SỬA CHỮA BÌNH NÓNG LẠNH GIÁ RẺ UY TÍN</h2>
                <div style={{borderRadius: '10px', borderColor: 'black', borderWidth: '2px', borderStyle: 'dotted', padding: '2rem', marginTop: '2rem'}}>
                    
                    <p style={{fontSize:'20px', fontWeight: 'bold'}}>Có hàng trăm cơ sở cung cấp dịch vụ sửa bình nóng lạnh tại Bình Dương & Tp. Hồ Chí Minh nhưng không phải đơn vị nào cũng uy tín và chất lượng như tại đây. Cùng xem lý do gì đã làm nên thương hiệu sửa chữa bình nóng lạnh số 1 Bình Dương & Tp. Hồ Chí Minh.</p>
                    <p style={{fontSize: '18px', marginTop: '0.5rem'}}>1. Chỉ lấy tiền khi máy nóng lạnh của khách hàng hoạt động lại bình thường.</p>
                    <p style={{fontSize: '18px'}}>2. Hội tụ đội ngũ thợ có văn hóa và trình độ tay nghề ít nhất 2 năm kinh nghiệm làm sửa chữa điện lạnh.</p>
                    <p style={{fontSize: '18px'}}>3. Bảo hành theo đúng cam kết bạn đầu, không bùng, không gạt khách hàng.</p>
                    <p style={{fontSize: '18px'}}>4. Giá dịch vụ thuộc top thấp nhất trên thị trường và đảm bảo 100% không bao giờ chặt chém hay móc túi khách hàng. Nếu bạn phát hiện nhân viên nào của chúng tôi có hành vi móc túi vui lòng báo ngay cho <b>Điện lạnh QT</b> để nhân viên đó bồi thường gấp đôi.</p>
                    <p style={{fontSize: '18px'}}>5. Chỉ 15 phút sau khi gọi điện sẽ có thợ tới.</p>
                    <p style={{fontSize: '18px'}}>6. Phục vụ khách 24/7, không có khái niệm nghỉ thứ 7 và chủ nhật</p>
                    <p style={{fontSize: '18px'}}>7. Linh kiện chính hãng bảo hành từ 6 – 24 tháng</p>
                </div>
            </div>
        </Wrapper>
        <Wrapper>
            <div className='container1'>
                <h2>CÁC LOẠI BÌNH NÓNG LẠNH TRÊN THỊ TRƯỜNG MÀ CHÚNG TÔI SỬA CHỮA:</h2>
                <div className='content-wrapper'>
                    <div className='list-wrapper'>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Picenza</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Sơn Hà</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Ariston</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Kangaroo</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Funiki</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Electrolux</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Ferroli</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Rossi</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Midea</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Atlantic</p>
                            <p style={{fontSize: '18px'}}>- Sửa bình nóng lạnh Olympic</p>
                    </div>
                    <img src="/images/binhnonglanh1.jpg" className='image1' alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        
        <Wrapper>
            <div className='container'>
                <h2>HÌNH ẢNH THI CÔNG</h2>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <img src="/images/nonglanh1.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/nonglanh2.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/nonglanh3.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                    <img src="/images/nonglanh4.jpg" style={{width: '24%', objectFit: 'cover', margin: 'auto'}} alt='thicong'/>
                </div>
            </div>
        </Wrapper>
        <Truested/>
    </>
      
    
  )
}
const Wrapper = styled.section`
padding: 5rem 0;
background-color: #f2ffff;
.container1 {
    width: 63%;
    margin: 0 auto;
}
.container2{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.content-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.vattu{
    margin-top: 1rem;
    width: 48%;
}
.baoduong{
    margin-top: 1rem;
    width: 48%;
}
.h2vattu{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}

.list-wrapper {
    border-radius: 10px;
    border: 2px dotted black;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 45%;
    height: 50%;
}

.image1 {
    width: 35%;
    margin-right: 100px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .container1 {
        width: 100%;
    }
    .container2 {
        width: 100%;
        flex-direction: column-reverse;
    }

    .content-wrapper {
        flex-direction: column-reverse;
    }
    .vattu{
        width: 95%;
        margin: auto;
    }
    .baoduong{
        width: 95%;
        margin: auto;
    }
    .h2vattu{
        color: #004a82;
        text-align: center;
        padding: 0.2rem;
        margin-top: 2rem;
    }
    .list-wrapper {
        width: 95%;
        margin: auto;
    }

    .image1 {
        width: 95%;
        margin: auto;
    }
}
h2{
    color: #004a82;
    text-align: center;
    padding: 0.2rem;
}
h3{
    color: #004a82;
    text-align: center;
    font-size: 35px;
}
li{
    font-size: 18px;
}
.logo{
    height: 50%;
}
.table, th, td {
    border:2px solid blue;
    text-align: center;
    padding: 0.25rem;
}

`;

export default SuaBinhNongLanh
